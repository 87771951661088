import Icon, { CaretRightOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Popover, Row, Space, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NotePickModal } from '../..';
import { BookmarkOutlined } from '../../../../assets/icons/icons';
import { AdminRoutes } from '../../../../features/admin';
import { SelectTypeEnum, UserRoleEnum } from '../../../enums';
import {
  FaqInterface,
  FaqSingleInterface,
  LicenceInterface,
  NoteResponseInterfaceAll,
  TagScreenResponseInterface,
} from '../../../interfaces';
import { StoreStateInterface } from '../../../redux';
import { i18n, ViewerService } from '../../../services';
import NoDataComponent from '../../noData/noData.component';
import { UserActivityLogService } from '../../../api';
import { UserActionTypeEnum } from '../../../enums/userActionType.enum';

const { Text } = Typography;

interface Props {
  faqs?: Array<FaqInterface['attributes']>;
  tags?: TagScreenResponseInterface[];
  licenceData?: LicenceInterface;
  notes?: NoteResponseInterfaceAll;
  isDashboard?: boolean;
  validLicences?: LicenceInterface[];
}

export default function FAQCardComponent({ faqs, tags, licenceData, isDashboard, notes, validLicences }: Props) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [visible, setVisible] = useState(false);
  const [selectedFAQ, setSelectedFAQ] = useState<FaqSingleInterface>();
  const [selectedGroup, setSelectedGroup] = useState<FaqInterface['attributes']>();

  const availableNotes = licenceData?.attributes?.notesNum as number;

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    if (!((notes?.data.length as number) == availableNotes)) {
      setVisible(true);
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const { Panel } = Collapse;

  const onFileDownload = (faq: FaqSingleInterface, group: FaqInterface['attributes']) => {
    let uuid = group?.bilten?.pdfUuid ? group?.bilten?.pdfUuid : group?.professionalArticle?.pdfUuid;
    let page = faq?.pageNum;

    if (!uuid) return;
    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: UserActionTypeEnum.FILE_OPENED,
            extra: JSON.stringify({ type: 'faq', title: faq.title }),
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user',
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
    ViewerService.goToPdf(uuid, page);
  };

  const onClickAction = (faq: FaqSingleInterface, group: FaqInterface['attributes']) => {
    setSelectedFAQ(faq);
    setSelectedGroup(group);
    showModal();
  };

  const text = (question: FaqSingleInterface, group: FaqInterface['attributes'], index: number) => (
    <Row align="middle" gutter={18}>
      <Col style={{ display: 'flex' }}>
        <Space size={0} align="center" direction="horizontal">
          {group?.bilten?.pdfUuid || group?.professionalArticle?.pdfUuid ? (
            checkLicenceYear(group) ? (
              <Button
                type="link"
                onClick={() => onFileDownload(question, group)}
                icon={<LinkOutlined />}
                className="cardBtn"
              />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<LinkOutlined />} />
              </Popover>
            )
          ) : (
            <Popover
              placement="topRight"
              content={i18n.translate(`common.licence.content`)}
              title={i18n.translate(`common.licence.title`)}
            >
              <Button disabled type="link" className="cardBtn" icon={<LinkOutlined />} />
            </Popover>
          )}
          {checkLicenceYear(group) ? (
            <Button
              type="link"
              className="cardBtn"
              onClick={() => onClickAction(question, group)}
              icon={<Icon component={BookmarkOutlined} />}
            />
          ) : (
            <Popover
              placement="topRight"
              content={i18n.translate(`common.licence.content`)}
              title={i18n.translate(`common.licence.title`)}
            >
              <Button
                disabled
                type="link"
                className="cardBtn"
                style={{ marginRight: 10 }}
                icon={<Icon component={BookmarkOutlined} />}
              />
            </Popover>
          )}
        </Space>
        <Text style={{ width: '100%' }} ellipsis={{ tooltip: `${question?.title}` }} className="text">
          {question?.title}
        </Text>
      </Col>
    </Row>
  );

  const genHeader = (faq: FaqInterface['attributes']) => {
    return (
      <span className="header">
        <Text className="extraText">{faq?.name}</Text>
        {userAuth.user?.roles === UserRoleEnum.ADMIN && (
          <Link to={AdminRoutes.FAQ.path + `/${faq?.id}`} key={AdminRoutes.FAQ.path + `/${faq?.id}`}>
            <Button type="link" className="cardBtn" icon={<EditOutlined />} />
          </Link>
        )}
      </span>
    );
  };

  const genExtra = (faq: FaqInterface['attributes']) => {
    let text = '';
    if (faq && faq.bilten) {
      text = 'BJN ' + faq.bilten?.issueNum;
    }
    if (faq && faq.professionalArticle) {
      text =
        faq.professionalArticle?.title +
        (faq.professionalArticle?.publishedAt
          ? `, ${moment(faq.professionalArticle?.publishedAt).format('YYYY')}`
          : '');
    }
    return (
      <div className="extra">
        <Text className="extraText">{text}</Text>
      </div>
    );
  };

  const modal = () => {
    if (!selectedFAQ) return;
    return (
      <NotePickModal
        isExternal={true}
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        external={selectedFAQ}
        type="frequent_questions"
        typeId={selectedFAQ.id}
        breadcrumb={'Mreža znanja/Česta pitanja/' + selectedGroup?.name}
        number={selectedFAQ.id}
        isEditable={true}
        active={true}
        licenceData={licenceData as LicenceInterface}
        validLicences={validLicences}
      />
    );
  };

  const sortedFaqs = (faqs: FaqInterface['attributes'][]): Array<FaqInterface['attributes'][] | undefined> => {
    const tagYearsObject = tags?.find((x) => x.selectType === SelectTypeEnum.YEAR);
    const tagYearsArray = tagYearsObject?.children;

    let faqArray = faqs;
    let unsorted: FaqInterface['attributes'][] = [];

    function onlyUnique(value: any, index: any, self: string | any[]) {
      return self.indexOf(value) === index;
    }

    let yearArray: string[] = [];
    let faqGroups: (FaqInterface['attributes'][] | undefined)[] = [];

    faqArray?.forEach((faq) => {
      faq?.tagIds?.forEach((tagId) => {
        const found = tagYearsArray?.find((x) => x.tag?.id == tagId);
        if (found) {
          yearArray.push(moment(found.tag?.name).format('YYYY.'));
        } else {
          yearArray.push('');
        }
      });
    });

    let unique = yearArray?.filter(onlyUnique);

    unique?.forEach((entry) => {
      let sameYearFaqs: FaqInterface['attributes'][] = [];
      faqArray?.forEach((faq) => {
        faq?.tagIds?.forEach((tagId) => {
          const found = tagYearsArray?.find((x) => x.tag?.id == tagId);
          if (found && entry == moment(found.tag?.name).format('YYYY.')) {
            sameYearFaqs.push(faq);
          }
        });
      });
      faqGroups.push(sameYearFaqs);
    });

    faqArray?.forEach((faq) => {
      let foundOne = false;
      faq?.tagIds?.forEach((tagId) => {
        const found = tagYearsArray?.find((x) => x.tag?.id == tagId);
        if (found) {
          foundOne = true;
        }
      });
      if (!foundOne) unsorted.push(faq);
    });

    faqGroups.push(unsorted);

    return faqGroups.filter((x) => (x?.length as number) > 0);
  };

  const checkLicenceYear = (faq: FaqInterface['attributes'] | undefined) => {
    const faqYear = getYear(faq);

    if (userAuth.user?.roles === UserRoleEnum.ADMIN) return true;

    if (validLicences?.length) {
      let access = false;

      validLicences.forEach((licenceData) => {
        if (faqYear == licenceData.attributes?.year) {
          access = true;
        }
        if (licenceData.attributes?.childrenLicences?.length) {
          if (licenceData.attributes?.childrenLicences?.find((x) => x.active && x.year == faqYear)) {
            access = true;
          }
        }
      });

      return access;
    } else {
      return false;
    }
  };

  const getYear = (faq: FaqInterface['attributes'] | undefined) => {
    const tagYearsObject = tags?.find((x) => x.selectType === SelectTypeEnum.YEAR);
    const tagYearsArray = tagYearsObject?.children;

    let yearString = i18n.translate('common.unsorted');

    faq?.tagIds?.forEach((tagId) => {
      const found = tagYearsArray?.find((x) => x.tag?.id == tagId);
      if (found) {
        yearString = moment(found.tag?.name).format('YYYY');
      }
    });

    return yearString;
  };

  const genYearHeader = (faq: FaqInterface['attributes'] | undefined) => {
    const tagYearsObject = tags?.find((x) => x.selectType === SelectTypeEnum.YEAR);
    const tagYearsArray = tagYearsObject?.children;

    let headerString;

    if (isDashboard) {
      headerString = '';
    } else {
      headerString = i18n.translate('common.unsorted');
    }

    faq?.tagIds?.forEach((tagId) => {
      const found = tagYearsArray?.find((x) => x.tag?.id == tagId);
      if (found) {
        headerString = moment(found?.tag?.name).format('YYYY.');
      }
    });

    return (
      <span className="headerYear">
        <Text className="extraTextYear">{headerString}</Text>
      </span>
    );
  };

  const getDefaultActiveKeys = (faqs: FaqInterface['attributes'][]) => {
    const tagYearsObject = tags?.find((x) => x.selectType === SelectTypeEnum.YEAR);
    const tagYearsArray = tagYearsObject?.children;

    let faqArray = faqs;

    function onlyUnique(value: any, index: any, self: string | any[]) {
      return self.indexOf(value) === index;
    }

    let yearArray: string[] = [];
    let defaultOpenArray: string[] = [];

    faqArray?.forEach((faq) => {
      faq?.tagIds?.forEach((tagId) => {
        const found = tagYearsArray?.find((x) => x.tag?.id == tagId);
        if (found) {
          yearArray.push(moment(found.tag?.name).format('YYYY.'));
        } else {
          yearArray.push('');
        }
      });
    });

    let unique = yearArray?.filter(onlyUnique);

    unique?.forEach((entry, index) => {
      defaultOpenArray.push('faq_sorted' + index);
    });

    if (defaultOpenArray.length === 0) defaultOpenArray.push('faq_sorted' + 0);
    if (defaultOpenArray.length > 0) defaultOpenArray.push('faq_sorted' + defaultOpenArray.length);

    return defaultOpenArray;
  };

  return (
    <div className="faqCard">
      {faqs?.length ? (
        <Collapse
          className="noPointer"
          collapsible={undefined}
          activeKey={getDefaultActiveKeys(faqs)}
          ghost
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}
        >
          {sortedFaqs(faqs)?.length
            ? [...sortedFaqs(faqs)].map((group, index: number) => (
                <Panel
                  className="noPointer"
                  showArrow={false}
                  forceRender
                  key={'faq_sorted' + index}
                  header={genYearHeader(group?.length && group[0] ? group[0] : undefined)}
                  style={{ border: 'none' }}
                >
                  <Collapse
                    className="hasPointer"
                    ghost
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                  >
                    {group?.length
                      ? [...group].map((faq: FaqInterface['attributes'], index: number) => (
                          <Panel
                            className="hasPointer"
                            key={'faq' + index}
                            header={genHeader(faq)}
                            extra={genExtra(faq)}
                          >
                            {faq?.frequentQuestions
                              ?.sort(function (a, b) {
                                return a?.position - b?.position;
                              })
                              .map((question, j) => (
                                <div key={'question' + j}>{question && text(question, faq, j)} </div>
                              ))}
                          </Panel>
                        ))
                      : null}
                  </Collapse>
                </Panel>
              ))
            : null}
        </Collapse>
      ) : (
        <NoDataComponent />
      )}
      {modal()}
    </div>
  );
}
