import { SearchOutlined, FilePdfOutlined, MoreOutlined, EditOutlined, LinkOutlined, BankOutlined, ExceptionOutlined, BarsOutlined } from '@ant-design/icons';
import { Col, DatePicker, Dropdown, InputRef, Menu, notification, Popover, Radio, RadioChangeEvent, Row, TablePaginationConfig, Tooltip } from 'antd';
import { Button, Input, Space, Table, Typography } from 'antd';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { FilterConfirmProps, FilterValue, SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DecisionService, FileService, FilterNameEnumHR, StoreStateInterface, UserActivityLogService, UserRoleEnum } from '../../..';
import { AdminRoutes } from '../../../../features/admin';
import { DisplayDateFormat } from '../../../constants';
import { AllegationInterface, AppealInterface, DecisionInterface, LicenceInterface, SummaryInterface, TagScreenResponseInterface } from '../../../interfaces';
import { DownloadService, i18n, ViewerService } from '../../../services';
import localeHR from 'antd/es/date-picker/locale/hr_HR';
import localeEN from 'antd/es/date-picker/locale/en_GB';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { LicenceEnum } from '../../../enums/licence.enum';
import useWindowSize from '../../../hooks/useWindowSize';
import RhPracticeDetailsDrawer from '../../rhPracticeDetailsDrawer/rhPracticeDetailsDrawer.component';
import { UserActionTypeEnum } from '../../../enums/userActionType.enum';

const { Text } = Typography;

interface DataType {
  key: number | string;
  active: boolean;
  clazz: string;
  client: string;
  appellant: string;
  subject: string;
  type: string;
  decisionDate: string;
  appealInvestmentDate: string;
  decisionPublishDate: string;
  decisionPdfUuid: string;
  pmPdfUuid: string;
  zznpPdfUuid: string;
  decisionWaitTime: number;
  lawFirm: string;
  decisionSignatory: string;
  councilMembers: string;
  dkomDecisionLink: string;
  dkomDecisionPdfUuid: string;
  hasVerdict: boolean;
  tag: any[];
  createdDate: string;
  modifiedDate: string;
  createdBy: number;
  modifiedBy: number;
  tagIds: Array<number>;
  verdict: {
    id: number;
    decisionId: number;
    verdictNum: string;
    plaintiff: string;
    verdictPdfUuid: string;
    vusVerdictPdfUuid: string;
    vusVerdictLink: string;
    verdictDate: string;
    verdictPublishDate: string;
    active: boolean;
    tagIds: Array<number>;
  };
  decisionAppealAllegations: Array<AllegationInterface['attributes']>;
  decisionAppealResponses: Array<AppealInterface['attributes']>;
  decisionSummaries: Array<SummaryInterface['attributes']>;
}

interface Props {
  decisions?: DecisionInterface[];
  tags?: Array<TagScreenResponseInterface>;
  licenceData?: LicenceInterface;
  onChange?: (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) => void;
  refreshTable?: () => void;
  clearColumnFilters?: () => void;
  validLicences?: LicenceInterface[];
}

export default function DKOMTableComponent({ decisions, tags, licenceData, refreshTable, onChange, clearColumnFilters, validLicences }: Props) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const windowSize = useWindowSize();

  const [filtered, setFiltered] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [detailsData, setDetailsData] = useState();
  const [currentDecision, setCurrentDecision] = useState();

  const getType = (record: any) => {
    let typeName = '';
    const parentTag = tags?.find((tag) => tag.filter && tag.filter.name == FilterNameEnumHR.PURCHASE_TYPE);
    if (parentTag && parentTag.children?.length) {
      parentTag.children?.forEach((child) => {
        const id = record?.tagIds?.find((id: number | undefined) => id == child.tag.id);
        if (id) typeName = child.tag.name;
      });
    }
    return typeName;
  };

  const checkLicenceYear = (record: DataType) => {
    const decisionYear = moment(record?.appealInvestmentDate, DisplayDateFormat).format('YYYY');

    if (userAuth.user?.roles === UserRoleEnum.ADMIN) return true;

    if (validLicences?.length && validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
      let access = false;

      validLicences.filter(x => x.attributes?.licenceType === LicenceEnum.PREMIUM).forEach(licenceData => {
        if (decisionYear == licenceData.attributes?.year) {
          access = true;
        }
        if (licenceData.attributes?.childrenLicences?.length) {
          if (licenceData.attributes?.childrenLicences?.find((x) => x.active && x.year == decisionYear)) {
            access = true;
          }
        }
      });

      return access;
    } else {
      return false;
    }
  };

  const data: DataType[] = decisions?.map((decision) => {
    return {
      key: decision?.id,
      type: getType(decision?.attributes),
      ...decision?.attributes,
      decisionDate: decision?.attributes?.decisionDate ? moment(decision?.attributes?.decisionDate).format(DisplayDateFormat) : '',
      appealInvestmentDate: decision?.attributes?.appealInvestmentDate ? moment(decision?.attributes?.appealInvestmentDate).format(DisplayDateFormat) : '',
      decisionPublishDate: decision?.attributes?.decisionPublishDate ? moment(decision?.attributes?.decisionPublishDate).format(DisplayDateFormat) : ''
    };
  }) as unknown as DataType[];

  type DataIndex = keyof DataType;

  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    setFiltered(true);
    confirm();
  };

  const handleReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void) => {
    setFiltered(false);
    clearFilters();
    confirm();
  };

  const handleClearAllFilters = () => {
    setFiltered(false);
    if (clearColumnFilters)
      clearColumnFilters()
  };

  const menu = (decisionId: number, uuid: string, active: boolean, record: any) => {
    let items = [
      userAuth.user?.roles === UserRoleEnum.ADMIN && {
        label: <a onClick={() => onDecisionActivation(decisionId, active)}>{active ? i18n.translate(`dkom.table.actions.deactivate`) : i18n.translate(`dkom.table.actions.activate`)}</a>,
        key: '0'
      },
      {
        label: <a onClick={() => onFileDownload(uuid, record)}>{i18n.translate(`dkom.table.actions.download`)}</a>,
        key: '1'
      },
    ] as ItemType[]
    return <Menu items={items}/>
  };

  const onDecisionActivation = (id: number, active: boolean) => {
    const decision = {
      data: {
        id: id,
        type: 'decision',
        attributes: {
          active: !active
        }
      }
    }
    DecisionService.activation(decision).subscribe(
      (response: any) => {
        refreshTable && refreshTable();
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const onFileDownload = (uuid: string, record: any) => {
    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: UserActionTypeEnum.FILE_OPENED,
            extra: JSON.stringify({type: 'decision', documentType: 'BJN uređena odluka', name: record.clazz, subject: record.subject})
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
    FileService.temp(uuid).subscribe(
      (response: any) => {
        if (response.url) {
          notification['success']({ message: i18n.translate('api.downloadSuccessMessage'), duration: 2 });
          var link = document.createElement('a');
          link.href = response.url;
          link.download = 'file.pdf';
          link.dispatchEvent(new MouseEvent('click'));
        } else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const onFileView = (uuid: string, record: DataType, documentType: string) => {
    if (!uuid) return;
    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: checkLicenceYear(record) ? UserActionTypeEnum.FILE_DOWNLOADED : UserActionTypeEnum.FILE_OPENED,
            extra: JSON.stringify({type: 'decision', documentType: documentType, name: record.clazz, subject: record.subject})
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
    if (checkLicenceYear(record)) {
      DownloadService.download(uuid)
    } else {
      ViewerService.goToPdf(uuid);
    }
  };

  const onEditedFileView = (uuid: string, record: DataType) => {
    if (!uuid) return;
    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: UserActionTypeEnum.FILE_DOWNLOADED,
            extra: JSON.stringify({type: 'decision', documentType: 'BJN uređena odluka', name: record.clazz, subject: record.subject})
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
    if (checkLicenceYear(record)) {
      DownloadService.download(uuid);
    } else {
      //ViewerService.goToPdf(uuid);
      notification['warning']({ message: i18n.translate('common.licence.content'), duration: 4 });
    }
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
      dataIndex != 'appealInvestmentDate' && dataIndex != 'decisionDate' && dataIndex != 'decisionPublishDate' ? (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys([e.target.value, selectedKeys[1]])
            }}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Radio.Group defaultValue={true} style={{marginBottom: 8}} onChange={(e) => {
            setSelectedKeys([selectedKeys[0], e.target.value]);
          }}>
            <Radio value={true}>{i18n.translate('common.filter.exactMatch')}</Radio>
            <Radio value={false}>{i18n.translate('common.filter.noExactMatch')}</Radio>
          </Radio.Group>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters, confirm)}
              size="small"
            >
              {i18n.t('genericButtons.clear')}
            </Button>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
            >
              {i18n.t('genericButtons.search')}
            </Button>
          </Space>
        </div>
      ) : (
        <div style={{ padding: 8 }}>
          <DatePicker
            locale={userAuth.lang == 'hr' ? localeHR : localeEN}
            format={DisplayDateFormat}
            onChange={(e) =>
              setSelectedKeys(moment(e).format(DisplayDateFormat) ? [moment(e).format(DisplayDateFormat)] : [])
            }
            style={{ marginBottom: 8, display: 'block' }}
            allowClear={false}
          />
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters, confirm)}
              size="small"
            >
              {i18n.t('genericButtons.clear')}
            </Button>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
            >
              {i18n.t('genericButtons.search')}
            </Button>
          </Space>
        </div>
      ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const getTypeFilters = () => {
    let typesArray: any[] = [];
    const parentTag = tags?.find((tag) => tag.filter && tag.filter.name == FilterNameEnumHR.PURCHASE_TYPE);
    if (parentTag && parentTag.children?.length) {
      parentTag.children?.forEach((child) => {
        typesArray.push({text: child.tag?.name, value: child.tag?.id});
      });
    }
    return typesArray;
  };

  const checkIfMobile = () => {
    if ((/Android/i.test(navigator.userAgent))) {
      return true;
    }
    if ((/iPhone/i.test(navigator.userAgent))) {
      return true;
    }
    else {
      return false;
    }
  };

  const onDrawerOpen = (detailsArray: any, title: 'allegation' | 'response' | 'summary', decision: any) => {
    const translatedTitle = i18n.t(`rhPracticeDrawer.dkom.titles.${title}`);

    setDrawerTitle(translatedTitle);
    setDetailsData(detailsArray);
    setCurrentDecision(decision);
    setDrawerOpen(true);
  };

  const onDrawerClose = () => {
    setDetailsData(undefined);
    setCurrentDecision(undefined);
    setDrawerOpen(false);
  };

  //OVO NE TREBA PROVJERE - OVO JE ZA ADMINOVU TABLICA
  const columns: ColumnsType<DataType> = [
    {
      dataIndex: 'subcategories',
      key: 'subcategories',
      width: '25px',
      fixed: windowSize.width as number > 425 && !checkIfMobile() ? 'left' : false,
      render: (text, record, index) => (
        <Col>
          <Row style={{justifyContent: 'center', marginBottom: 12}}>
            {record.decisionAppealAllegations?.length ? (
              <Tooltip
                title={i18n.translate(`rhPracticeDrawer.dkom.titles.allegation`)}
              >
                <Button onClick={() => onDrawerOpen(record.decisionAppealAllegations, 'allegation', record)} type="link" icon={<BankOutlined />} />
              </Tooltip>
            ) : (
              <Popover
                title={<span className="text-bold">{i18n.translate(`rhPracticeDrawer.dkom.titles.allegation`)}</span>}
                content={i18n.translate('common.licence.noTitle')}
              >
                <Button disabled type="link" className="cardBtn" icon={<BankOutlined />} />
              </Popover>
            )}
          </Row>
          
          <Row style={{justifyContent: 'center', marginBottom: 12}}>
            {record.decisionAppealResponses?.length ? (
              <Tooltip
                title={i18n.translate(`rhPracticeDrawer.dkom.titles.response`)}
              >
                <Button onClick={() => onDrawerOpen(record.decisionAppealResponses, 'response', record)} type="link" icon={<ExceptionOutlined />} />
              </Tooltip>
            ) : (
              <Popover
                title={<span className="text-bold">{i18n.translate(`rhPracticeDrawer.dkom.titles.response`)}</span>}
                content={i18n.translate('common.licence.noTitle')}
              >
                <Button disabled type="link" className="cardBtn" icon={<ExceptionOutlined />} />
              </Popover>
            )}
          </Row>

          <Row style={{justifyContent: 'center', marginBottom: 12}}>
            {record.decisionSummaries?.length ? (
              <Tooltip
                title={i18n.translate(`rhPracticeDrawer.dkom.titles.summary`)}
              >
                <Button onClick={() => onDrawerOpen(record.decisionSummaries, 'summary', record)} type="link" icon={<BarsOutlined />} />
              </Tooltip>
            ) : (
              <Popover
                title={<span className="text-bold">{i18n.translate(`rhPracticeDrawer.dkom.titles.summary`)}</span>}
                content={i18n.translate('common.licence.noTitle')}
              >
                <Button disabled type="link" className="cardBtn" icon={<BarsOutlined />} />
              </Popover>
            )}
          </Row>
        </Col>
      )
    },
    {
      title: i18n.translate('dkom.table.class'),
      dataIndex: 'clazz',
      key: 'clazz',
      width: '140px',
      fixed: windowSize.width as number > 425 && !checkIfMobile() ? 'left' : false,
      render: (text, record, index) => <span style={record.active ? {} : {color: 'red'}}>{text}</span>,
      ...getColumnSearchProps('clazz'),
    },
    {
      title: i18n.translate('dkom.table.decision'),
      key: 'decision',
      width: '190px',
      fixed: windowSize.width as number > 425 && !checkIfMobile() ? 'left' : false,
      render: (text, record, index) => (
        <Col>
          {/* <Row>
            <Text className="decText"> {i18n.translate('dkom.table.editedDec')}: </Text>
            {record.decisionPdfUuid ? (
              <Button onClick={() => onEditedFileView(record.decisionPdfUuid, record)} type="link" icon={<FilePdfOutlined />} />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            )}
          </Row> */}
          <Row>
            <Text className="decText"> {i18n.translate('dkom.table.decision')}: </Text>
            {record.dkomDecisionPdfUuid ? (
              <Button onClick={() => onFileView(record.dkomDecisionPdfUuid, record, 'DKOM originalna odluka')} type="link" icon={<FilePdfOutlined />} />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            )}
            
            {record.dkomDecisionLink ? (
              <a target="_blank" href={record.dkomDecisionLink}> <Button type="link" icon={<LinkOutlined />} /> </a>
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<LinkOutlined />} />
              </Popover>
            )}
          </Row>
          {record.pmPdfUuid ? <Row>
            <Text className="decText"> {i18n.translate('dkom.table.pmPdf')}: </Text>
            {record.pmPdfUuid ? (
              <Button onClick={() => onFileView(record.pmPdfUuid, record, 'PM odluka')} type="link" icon={<FilePdfOutlined />} />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            )}
          </Row> : null}
          {record.zznpPdfUuid ? <Row>
            <Text className="decText"> {i18n.translate('dkom.table.zznpPdf')}: </Text>
            {record.zznpPdfUuid ? (
              <Button onClick={() => onFileView(record.zznpPdfUuid, record, 'ZZNP odluka')} type="link" icon={<FilePdfOutlined />} />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            )}
          </Row> : null}
        </Col>
      ),
    },
    {
      title: i18n.translate('dkom.table.client'),
      dataIndex: 'client',
      key: 'client',
      width: '180px',
      ...getColumnSearchProps('client'),
    },
    {
      title: i18n.translate('dkom.table.appellant'),
      dataIndex: 'appellant',
      key: 'appellant',
      width: '180px',
      ...getColumnSearchProps('appellant'),
    },
    {
      title: i18n.translate('dkom.table.topic'),
      dataIndex: 'subject',
      key: 'subject',
      width: '240px',
      ...getColumnSearchProps('subject'),
    },
    {
      title: i18n.translate('dkom.table.type'),
      dataIndex: 'type',
      key: 'type',
      width: '130px',
      render: (text, record, index) => <span>{text ? text : '—'}</span>,
      //...getColumnSearchProps('type'),
      filters: getTypeFilters(),
    },
    {
      title: i18n.translate('dkom.table.dateofapp'),
      dataIndex: 'appealInvestmentDate',
      key: 'appealInvestmentDate',
      width: '135px',
      //...getColumnSearchProps('appealInvestmentDate'),
    },
    {
      title: i18n.translate('dkom.table.dateofdec'),
      dataIndex: 'decisionDate',
      key: 'decisionDate',
      width: '160px',
      //...getColumnSearchProps('decisionDate'),
    },
    {
      title: i18n.translate('dkom.table.dateofpubl'),
      dataIndex: 'decisionPublishDate',
      key: 'decisionPublishDate',
      width: '140px',
      //...getColumnSearchProps('decisionPublishDate'),
    },
    {
      title: i18n.translate('dkom.table.waittime'),
      dataIndex: 'decisionWaitTime',
      key: 'decisionWaitTime',
      width: '170px',
      sorter: (a, b) => a.decisionWaitTime - b.decisionWaitTime,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => <span>{record.decisionWaitTime > 0 ? `${record.decisionWaitTime} ${i18n.t('dkom.table.days')}` : ``}</span>,
    },
    {
      title: i18n.translate('dkom.table.lawFirm'),
      dataIndex: 'lawFirm',
      key: 'lawFirm',
      width: '185px',
      ...getColumnSearchProps('lawFirm'),
    },
    {
      title: i18n.translate('dkom.table.signatory'),
      dataIndex: 'decisionSignatory',
      key: 'decisionSignatory',
      width: '185px',
      ...getColumnSearchProps('decisionSignatory'),
    },
    {
      title: i18n.translate('dkom.table.councilMembers'),
      dataIndex: 'councilMembers',
      key: 'councilMembers',
      width: '200px',
      ...getColumnSearchProps('councilMembers'),
    },
    {
      title: i18n.translate('dkom.table.vusDecision'),
      dataIndex: 'verdict',
      key: 'verdict',
      width: '150px',
      sorter: (a: any, b: any) => a.hasVerdict - b.hasVerdict,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <span>{record.hasVerdict ? i18n.t('dkom.table.hasVerdict') : i18n.t('dkom.table.noVerdict')}</span>
      ),
    },
    {
      title: i18n.translate('dkom.table.actions.title'),
      dataIndex: 'actions',
      key: 'actions',
      width: '130px',
      render: (text, record, index) => (
        <Row>
          <Col>
            {userAuth.user?.roles === UserRoleEnum.ADMIN && (
              <Link
                to={AdminRoutes.DKOM_DECISIONS.fullPath + `/${record.key}`}
                key={AdminRoutes.DKOM_DECISIONS.fullPath + `/new`}
              >
                <Button type="link" icon={<EditOutlined />} />
              </Link>
            )}
          </Col>
          <Col>
            <Dropdown overlay={menu(record.key as number, record.decisionPdfUuid, record.active, record)} trigger={['click']}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <Button type="link" icon={<MoreOutlined />} />
                </Space>
              </a>
            </Dropdown>
          </Col>
        </Row>
      ),
    },
  ];

  const columnsUser: ColumnsType<DataType> = [
    {
      dataIndex: 'subcategories',
      key: 'subcategories',
      width: '25px',
      fixed: windowSize.width as number > 425 && !checkIfMobile() ? 'left' : false,
      render: (text, record, index) => (
        <Col>
          <Row style={{justifyContent: 'center', marginBottom: 12}}>
            {record.decisionAppealAllegations?.length && validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM) ? (
              <Tooltip
                title={i18n.translate(`rhPracticeDrawer.dkom.titles.allegation`)}
              >
                <Button onClick={() => onDrawerOpen(record.decisionAppealAllegations, 'allegation', record)} type="link" icon={<BankOutlined />} />
              </Tooltip>
            ) : (
              <Popover
                title={<span className="text-bold">{i18n.translate(`rhPracticeDrawer.dkom.titles.allegation`)}</span>}
                content={i18n.translate('common.licence.content')}
              >
                <Button disabled type="link" className="cardBtn" icon={<BankOutlined />} />
              </Popover>
            )}
          </Row>
          
          <Row style={{justifyContent: 'center', marginBottom: 12}}>
            {record.decisionAppealResponses?.length && validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM) ? (
              <Tooltip
                title={i18n.translate(`rhPracticeDrawer.dkom.titles.response`)}
              >
                <Button onClick={() => onDrawerOpen(record.decisionAppealResponses, 'response', record)} type="link" icon={<ExceptionOutlined />} />
              </Tooltip>
            ) : (
              <Popover
                title={<span className="text-bold">{i18n.translate(`rhPracticeDrawer.dkom.titles.response`)}</span>}
                content={i18n.translate('common.licence.content')}
              >
                <Button disabled type="link" className="cardBtn" icon={<ExceptionOutlined />} />
              </Popover>
            )}
          </Row>

          <Row style={{justifyContent: 'center', marginBottom: 12}}>
            {record.decisionSummaries?.length && validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM) ? (
              <Tooltip
                title={i18n.translate(`rhPracticeDrawer.dkom.titles.summary`)}
              >
                <Button onClick={() => onDrawerOpen(record.decisionSummaries, 'summary', record)} type="link" icon={<BarsOutlined />} />
              </Tooltip>
            ) : (
              <Popover
                title={<span className="text-bold">{i18n.translate(`rhPracticeDrawer.dkom.titles.summary`)}</span>}
                content={i18n.translate('common.licence.content')}
              >
                <Button disabled type="link" className="cardBtn" icon={<BarsOutlined />} />
              </Popover>
            )}
          </Row>
        </Col>
      )
    },
    {
      title: i18n.translate('dkom.table.class'),
      dataIndex: 'clazz',
      key: 'clazz',
      width: '140px',
      fixed: windowSize.width as number > 425 && !checkIfMobile() ? 'left' : false,
      render: (text, record, index) => <span style={record.active ? {} : {color: 'red'}}>{text}</span>,
      ...getColumnSearchProps('clazz'),
    },
    {
      title: i18n.translate('dkom.table.decision'),
      key: 'decision',
      width: '190px',
      fixed: windowSize.width as number > 425 && !checkIfMobile() ? 'left' : false,
      render: (text, record, index) => (
        <Col>
          {/* <Row>
            <Text className="decText"> {i18n.translate('dkom.table.editedDec')}: </Text>
            {record.decisionPdfUuid ? (
              !checkLicenceYear(record) ? (
                <Popover
                  placement="topRight"
                  content={i18n.translate(`common.licence.content`)}
                  title={i18n.translate(`common.licence.title`)}
                >
                  <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
                </Popover>
              ) : (
                <Button onClick={() => onEditedFileView(record.decisionPdfUuid, record)} type="link" icon={<FilePdfOutlined />} />
              )
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            )}
          </Row> */}
          
          <Row>
            <Text className="decText"> {i18n.translate('dkom.table.decision')}: </Text>
            {record.dkomDecisionPdfUuid ? (
              <Button onClick={() => onFileView(record.dkomDecisionPdfUuid, record, 'DKOM originalna odluka')} type="link" icon={<FilePdfOutlined />} />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            )}
            {record.dkomDecisionLink ? (
              <a target="_blank" href={record.dkomDecisionLink}> <Button type="link" icon={<LinkOutlined />} /> </a>
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<LinkOutlined />} />
              </Popover>
            )}
          </Row>
          {record.pmPdfUuid ? <Row>
            <Text className="decText"> {i18n.translate('dkom.table.pmPdf')}: </Text>
            {record.pmPdfUuid ? (
              <Button onClick={() => onFileView(record.pmPdfUuid, record, 'PM odluka')} type="link" icon={<FilePdfOutlined />} />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            )}
          </Row> : null}
          {record.zznpPdfUuid ? <Row>
            <Text className="decText"> {i18n.translate('dkom.table.zznpPdf')}: </Text>
            {record.zznpPdfUuid ? (
              <Button onClick={() => onFileView(record.zznpPdfUuid, record, 'ZZNP odluka')} type="link" icon={<FilePdfOutlined />} />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            )}
          </Row> : null}
        </Col>
      ),
    },
    {
      title: i18n.translate('dkom.table.client'),
      dataIndex: 'client',
      key: 'client',
      width: '180px',
      ...getColumnSearchProps('client'),
    },
    {
      title: i18n.translate('dkom.table.appellant'),
      dataIndex: 'appellant',
      key: 'appellant',
      width: '180px',
      ...getColumnSearchProps('appellant'),
    },
    {
      title: i18n.translate('dkom.table.topic'),
      dataIndex: 'subject',
      key: 'subject',
      width: '240px',
      ...getColumnSearchProps('subject'),
    },
    {
      title: i18n.translate('dkom.table.type'),
      dataIndex: 'type',
      key: 'type',
      width: '130px',
      render: (text, record, index) => <span>{text ? text : '—'}</span>,
      //...getColumnSearchProps('type'),
      filters: getTypeFilters(),
    },
    {
      title: i18n.translate('dkom.table.dateofapp'),
      dataIndex: 'appealInvestmentDate',
      key: 'appealInvestmentDate',
      width: '135px',
      //...getColumnSearchProps('appealInvestmentDate'),
    },
    {
      title: i18n.translate('dkom.table.dateofdec'),
      dataIndex: 'decisionDate',
      key: 'decisionDate',
      width: '160px',
      //...getColumnSearchProps('decisionDate'),
    },
    {
      title: i18n.translate('dkom.table.dateofpubl'),
      dataIndex: 'decisionPublishDate',
      key: 'decisionPublishDate',
      width: '140px',
      //...getColumnSearchProps('decisionPublishDate'),
    },
    {
      title: i18n.translate('dkom.table.waittime'),
      dataIndex: 'decisionWaitTime',
      key: 'decisionWaitTime',
      width: '170px',
      sorter: (a, b) => a.decisionWaitTime - b.decisionWaitTime,
      sortDirections: ['descend', 'ascend'],
      //defaultSortOrder: 'ascend',
      render: (text, record, index) => <span>{record.decisionWaitTime > 0 ? `${record.decisionWaitTime} ${i18n.t('dkom.table.days')}` : ``}</span>,
    },
    {
      title: i18n.translate('dkom.table.vusDecision'),
      dataIndex: 'verdict',
      key: 'verdict',
      width: '150px',
      sorter: (a: any, b: any) => a.hasVerdict - b.hasVerdict,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <span>{record.hasVerdict ? i18n.t('dkom.table.hasVerdict') : i18n.t('dkom.table.noVerdict')}</span>
      ),
    },
  ];

  return (
    <>
      <div className="dkom-table-container">
        {filtered && (
          <div className="dkom-table-remove-filter">
            <Button onClick={handleClearAllFilters}>{i18n.translate(`genericButtons.clearTableFilter`)}</Button>
          </div>
        )}
        <Table
          style={{ paddingBottom: 24 }}
          scroll={{ x: 1500, y: 680 }}
          columns={userAuth.user?.roles === UserRoleEnum.ADMIN ? columns : columnsUser}
          dataSource={data}
          pagination={false}
          locale={{ 
            emptyText: i18n.t('common.noData'),
            triggerDesc: i18n.t('common.sortDesc'),
            triggerAsc: i18n.t('common.sortAsc'),
            cancelSort: i18n.t('common.cancelSort'),
            filterReset: i18n.t('common.reset'),
          }} 
          className="dkomTable"
          onChange={onChange}
        />
      </div>
      <RhPracticeDetailsDrawer drawerTitle={drawerTitle} data={detailsData} drawerOpen={drawerOpen} onDrawerClose={onDrawerClose} decision={currentDecision} tags={tags} licenceData={licenceData} validLicences={validLicences} />
    </>
  );
}